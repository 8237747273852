import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { 
  CircularProgress, 
} from '@mui/material';
import { UserApi } from '../../api/user.api';
import { Pagination } from '../../components/Pagination';
import { Layout } from '../../components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { getConfirmDialog, setConfirmDialog, setSnackbar } from '../../store/ui';
import { getConfirmDialogContent, getConfirmDialogHeader } from '../../utilities';
import { UserCard } from '../../components/UserCard';
import { hasFeatures } from '../../store/auth';
import { Search } from '../../components/Search';
import { useSearch } from '../../components/Search/hook';
import { SnackbarTypes } from '../../constants';
import { usePagination, useUtility } from '../../utilities/hooks';

/**
 * @typedef {object} DialogProps
 * @property {boolean} open
 * @property {import('../../models/user.model').UserModel|null} user
 */

/**
 * @typedef {object} SnackbarProps
 * @property {boolean} open
 * @property {string} message
 * @property {import('@mui/material').SnackbarOrigin} anchorOrigin
 * @property {number} autoHideDuration
 */

const searchItems = [
  {
    label: 'User Name',
    value: 'user_name',
  },
  {
    label: 'User ID',
    value: 'user_id',
  },
  {
    label: 'Is NFT',
    value: 'is_nft',
  }
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ListPanel = styled.div`
  display: flex;
  flex-direction: column;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, .3);
`;

export const Users = () => {
  const dispatch = useDispatch();
  const canDeleteUser = useSelector(hasFeatures(['delete-user']));
  const confirmDialogProps = useSelector(getConfirmDialog);
  const confirmDialogDataUserId = confirmDialogProps.data?.user_id;
  const { navigate } = useUtility();
  /** @type {[import('../../models/user.model').UserModel[], (value: import('../../models/user.model').UserModel) => void]} */
  const [users, setUsers] = useState([]);
  const [loader, setLoader] = useState(false);
  const {
    searchItem,
    searchText,
    searchTextAsDebounced,
    getSearchTextByItemValue,
    searchInputChange,
    searchSelectChange,
  } = useSearch({
    key: 'users',
    item: searchItems[0],
    text: '',
  });
  const searchItemValue = searchItem?.value;

  const {
    page,
    limit,
    getPageState,
    setPageState,
    next,
    previous,
  } = usePagination({
    key: 'users',
  });

  const loadUsers = useCallback(async () => {
    try {
      setLoader(true);
      const data = await UserApi.getUsers({
        pageState: getPageState(
          searchTextAsDebounced?.length > 0
        ),
        limit,
        userId: getSearchTextByItemValue('user_id', true),
        userName: getSearchTextByItemValue('user_name', true),
        isNft: searchItemValue === 'is_nft' ? true : undefined,
      });
      setPageState(data.pageState);
      setUsers(data.users);
    } catch (error) {
      console.error(error);
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: "The users can't be loaded",
      }))
    } finally {
      setLoader(false);
    }
  }, [
    limit, 
    searchItemValue, 
    searchTextAsDebounced,
    getSearchTextByItemValue, 
    dispatch, 
    getPageState, 
    setPageState,
  ]);

  const dialogSubmit = useCallback(async () => {
    try {
      dispatch(setConfirmDialog({
        result: null,
      }));
      setLoader(true);
      await UserApi.deleteUser({
        userId: confirmDialogDataUserId,
      });
      loadUsers();
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Success,
        content: 'User has just been deleted',
      }))
    } catch (error) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: "User hasn't been deleted",
      }))
    } finally {
      dispatch(setConfirmDialog({
        open: false,
        result: null,
        data: null,
      }));
      setLoader(false);
    }
  }, [confirmDialogDataUserId, dispatch, loadUsers]);

  useEffect(() => {
    if (confirmDialogProps.result === true) {
      dialogSubmit();
    }
  }, [confirmDialogProps.result, dialogSubmit]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const userClick = useCallback((/** @type {import('../../models/user.model').UserModel} */ user) => () => {
    navigate(`/users/${user.user_id}`, {
      label: 'User',
      path: `/users/${user.user_id}`,
    });
  }, [navigate]);

  const userDelete = useCallback((/** @type {import('../../models/user.model').UserModel} */ user) => () => {
    if (!canDeleteUser) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: "You don't have access to delete a user",
      }));
      return;
    }
    dispatch(setConfirmDialog({
      open: true,
      header: getConfirmDialogHeader(),
      content: getConfirmDialogContent('user'),
      result: null,
      data: user,
    }));
  }, [canDeleteUser, dispatch]);

  const paginationNextClick = useCallback(() => {
    next();
    loadUsers();
  }, [next, loadUsers]);

  const paginationPreviousClick = useCallback(() => {
    previous();
    loadUsers();
  }, [previous, loadUsers]);

  return (
    <Layout
      breadcrumbs={[
        {
          link: '/',
          label: 'Home',
        },
        {
          label: 'Users',
        }
      ]}
    >
      <Container>
        <Search
          items={searchItems}
          item={searchItem}
          text={searchText}
          onInputChange={searchInputChange}
          onSelectChange={searchSelectChange}
        />
        <ListPanel>
          {users.map(user => (
            <UserCard
              key={user.user_id}
              user={user}
              isDeleteButton={canDeleteUser}
              onClick={userClick(user)}
              onDelete={userDelete(user)}
            />
          ))}
        </ListPanel>
        {!loader && (
          <Pagination
            page={page}
            onNextClick={paginationNextClick}
            onPreviousClick={paginationPreviousClick}
          />
        )}
        {loader && (
          <Loader>
            <CircularProgress variant='indeterminate' color="inherit" />
          </Loader>
        )}
      </Container>
    </Layout>
  );
};  