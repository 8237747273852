import styled from '@emotion/styled';
import { Box as MuiBox } from '@mui/system';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Header } from './Header';
import { Content } from './Content';
import { UserApi } from '../../api/user.api';
import { CircularProgress } from '@mui/material';
import { Layout } from '../../components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { getConfirmDialog, setConfirmDialog, setSnackbar } from '../../store/ui';
import { PostApi } from '../../api/post.api';
import { FormikProvider, useFormik } from 'formik';
import { SnackbarTypes, TextLabels } from '../../constants';
import { NftApi } from '../../api/nft.api';
import { PartnerAttributeKeys, UserAttributeKeys } from '../../constants/attributes';
import { getConfirmDialogContent, getConfirmDialogHeader } from '../../utilities';
import { PostModel } from '../../models/post.model';
import { useUtility } from '../../utilities/hooks';

const Container = styled(MuiBox)`
  display: flex;
  flex-direction: column;
  background-color: rgb(26, 27, 31);
  padding: 16px;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, .3);
`;

export const TABS = {
  POSTS: '0',
  REBOUNDS: '1',
  ABOUT: '2',
  Attributes: '3',
  NFT: '4',
};

/** @typedef {import('../../models/post.model').PostModel} PostModel */

/**
 * @returns {import('react').ReactNode}
 */
export const User = () => {
  const { navigate } = useUtility();
  const dispatch = useDispatch();
  const params = useParams();
  const userId = params.userId;
  const [tab, setTab] = useState(TABS.POSTS);
  /** @type {[import('../../models/user.model').UserModel, function]} */
  const [user, setUser] = useState(null);
  const [originalPosts, setOriginalPosts] = useState([]);
  const [metaOfOriginalPosts] = useState(null);
  const [reboundPosts, setReboundPosts] = useState([]);
  const [metaOfReboundPosts] = useState(null);
  const [nftPosts, setNftPosts] = useState([]);
  const [loader, setLoader] = useState(true);
  const [attributes, setAttributes] = useState({
    [UserAttributeKeys.Private]: false,
    [UserAttributeKeys.Campaign]: false,
    [UserAttributeKeys.Celebrity]: false,
    [UserAttributeKeys.DemoMode]: false,
    [UserAttributeKeys.SafeContent]: false,
    [UserAttributeKeys.Internal]: false,
    [UserAttributeKeys.Tester]: false,
    [PartnerAttributeKeys.Gold]: false,
    [PartnerAttributeKeys.Silver]: false,
    [PartnerAttributeKeys.Platinum]: false,
    [PartnerAttributeKeys.Invitation]: false,
  });

  const form = useFormik({
    initialValues: {
      userName_disable: true,
      username: '',
      email_disable: true,
      email: '',
      phoneNumber_disable: true,
      phoneNumber: '',
      fullBio_disable: true,
      fullBio: '',
      shortBio_disable: true,
      shortBio: '',
      password_disable: true,
      password: '',
      sale_allowed: false,
      sale_trusted: false,
      sale_started: false,
      sale_wallet_addresses: '',
    },
  });

  const {
    values,
    setValues,
  } = form;

  const loadOriginalPosts = useCallback(async (user, lastPostId) => {
    try {
      const { data } = await PostApi.getPostsOfRecrdByUserId({
        userId: user.user_id,
        lastPostId,
      })
      const newPosts = data.map(v => {
        const item = new PostModel({
          author_id: v.author_id,
          first_frame: v.first_frame,
          thumb_url: v.thumb,
          narrative: v.narrative,
          post_id: v.post_id,
          post_type: v.video_type,
          post_status: v.post_status,
          hashtags: v.hashtags,
          title: v.title,
          width: v.width,
          height: v.height,
          user: {
            user_name: v.user_name,
            user_id: v.user_id,
            full_name: v.user_fullname,
            userSettings: {
              attributes: v.user
            }
          }
        })
        return item;
      })
      if (lastPostId === undefined) {
        setOriginalPosts(newPosts);
      } else {
        setOriginalPosts(prev => [...prev, ...newPosts]);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const postsNext = useCallback(() => {
    const lastPost = originalPosts[originalPosts.length - 1];
    if (!lastPost) {
      return;
    }
    loadOriginalPosts(user, lastPost.post_id);
  }, [user, originalPosts, loadOriginalPosts]);

  const loadReboundPosts = useCallback(async (user, lastPostId) => {
    try {
      const { data } = await PostApi.getReboundPostsOfRecrdByUserId({
        userId: user.user_id,
        lastPostId,
      })
      const newPosts = data.map(v => {
        const item = new PostModel({
          author_id: v.author_id,
          first_frame: v.first_frame,
          thumb_url: v.thumb,
          narrative: v.narrative,
          post_id: v.post_id,
          post_type: v.video_type,
          post_status: v.post_status,
          hashtags: v.hashtags,
          title: v.title,
          width: v.width,
          height: v.height,
          user: {
            user_name: v.user_name,
            user_id: v.user_id,
            full_name: v.user_fullname,
            userSettings: {
              attributes: v.user
            }
          }
        })
        return item;
      })
      if (lastPostId === undefined) {
        setReboundPosts(newPosts);
      } else {
        setReboundPosts(prev => [...prev, ...newPosts]);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const reboundsNext = useCallback(() => {
    const lastPost = reboundPosts[reboundPosts.length - 1];
    if (!lastPost) {
      return;
    }
    loadReboundPosts(user, lastPost.post_id);
  }, [user, reboundPosts, loadReboundPosts]);

  const loadUserData = useCallback(async (user) => {
    loadOriginalPosts(user);
    loadReboundPosts(user);
    PostApi.getPosts({
      authorId: user.user_id,
      saleStatus: '>0',
      includes: ['user', 'user.userSettings'],
    }).then(({ posts: newNftPosts }) => {
      setNftPosts(newNftPosts);
    });
  }, [loadOriginalPosts, loadReboundPosts]);

  const loadUser = useCallback(async (loader = true) => {
    try {
      if (loader) setLoader(true);
      const newUser = await UserApi.getUser({ 
        userId,
      });
      setUser(newUser);
      loadUserData(newUser);
    } catch (error) {

    } finally {
      if (loader) setLoader(false);
    }
  }, [userId, loadUserData]);

  useEffect(() => {
    loadUser()
  }, [loadUser]);

  useEffect(() => {
    if (!user) {
      return;
    }
    const userSettings = user?.userSettings;
    const userAttributes = userSettings?.attributes ?? {};
    setValues({
      userName_disable: true,
      userName: user.user_name,
      email_disable: true,
      email: user.email_address ?? TextLabels.Empty,
      phoneNumber_disable: true,
      phoneNumber: user.phone_number ?? TextLabels.Empty,
      fullBio_disable: true,
      fullBio: user?.userSettings?.bio_full ?? '<No Full Bio>',
      shortBio_disable: true,
      shortBio: user?.userSettings?.bio_short ?? '<No Short Bio>',
      password_disable: true,
      password: '',
      sale_allowed: userSettings?.sale_allowed ?? false,
      sale_trusted: userSettings?.sale_trusted ?? false,
      sale_started: userSettings?.sale_started ?? false,
      sale_wallet_addresses: userSettings?.sale_wallet_addresses ?? '',
    });
    const getAttributesGetter = (values) => (key) => values[key] === '1';
    const get = getAttributesGetter(userAttributes);
    setAttributes({
      [UserAttributeKeys.Private]: get(UserAttributeKeys.Private),
      [UserAttributeKeys.Campaign]: get(UserAttributeKeys.Campaign),
      [UserAttributeKeys.Celebrity]: get(UserAttributeKeys.Celebrity),
      [UserAttributeKeys.DemoMode]: get(UserAttributeKeys.DemoMode),
      [UserAttributeKeys.SafeContent]: get(UserAttributeKeys.SafeContent),
      [UserAttributeKeys.Internal]: get(UserAttributeKeys.Internal),
      [UserAttributeKeys.Tester]: get(UserAttributeKeys.Tester),
      [PartnerAttributeKeys.Gold]: get(PartnerAttributeKeys.Gold),
      [PartnerAttributeKeys.Silver]: get(PartnerAttributeKeys.Silver),
      [PartnerAttributeKeys.Platinum]: get(PartnerAttributeKeys.Platinum),
      [PartnerAttributeKeys.Invitation]: get(PartnerAttributeKeys.Invitation),
    })
  }, [user, setValues]);

  const contentAboutSave = useCallback(async field => {
    try {
      const { user_id: userId } = user;
      await UserApi.updateUser(userId, {
        [field]: values[field],
      });
      loadUser(false);
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Success,
        content: `the ${field} of user has been updated`,
      }));
    } catch (error) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: `Error: the ${field} of user hasn't been updated`,
      }));
    } finally {
      setValues({
        ...values,
        [`${field}_disable`]: true,
      });
    }
  }, [user, values, setValues, loadUser, dispatch]);

  const attributesSave = useCallback(async (values) => {
    const { user_id: userId } = user;
    try {
      await UserApi.updateUser(userId, {
        attributes: {
          [UserAttributeKeys.Private]: values[UserAttributeKeys.Private],
          [UserAttributeKeys.Campaign]: values[UserAttributeKeys.Campaign],
          [UserAttributeKeys.Celebrity]: values[UserAttributeKeys.Celebrity],
          [UserAttributeKeys.DemoMode]: values[UserAttributeKeys.DemoMode],
          [UserAttributeKeys.SafeContent]: values[UserAttributeKeys.SafeContent],
          [UserAttributeKeys.Internal]: values[UserAttributeKeys.Internal],
          [UserAttributeKeys.Tester]: values[UserAttributeKeys.Tester],
          [PartnerAttributeKeys.Gold]: values[PartnerAttributeKeys.Gold],
          [PartnerAttributeKeys.Silver]: values[PartnerAttributeKeys.Silver],
          [PartnerAttributeKeys.Platinum]: values[PartnerAttributeKeys.Platinum],
          [PartnerAttributeKeys.Invitation]: values[PartnerAttributeKeys.Invitation],
        }
      });
      loadUser(false);
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Success,
        content: `the attribute of user has been updated`,
      }));
    } catch (error) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: `Error: the attribute of user hasn't been updated`,
      }));
    }
  }, [user, loadUser, dispatch]);

  const contentTabChange = useCallback(tab => {
    setTab(tab);
  }, []);

  const contentPostClick = useCallback((post) => {
    navigate(`/posts/${post.post_id}`, { label: 'Post' });
  }, [navigate]);

  const {
    result: deletePostResult,
    data: deletePostData,
  } = useSelector(getConfirmDialog);

  const deletePost = useCallback(
    async () => {
      try {
        setLoader(true);
        await PostApi.deletePost({ postId: deletePostData?.post_id });
        loadUser(true);
        dispatch(setSnackbar({
          open: true,
          type: SnackbarTypes.Success,
          content: "The post has been deleted",
        }));
      } catch (error) {
        console.error(error);
        dispatch(setSnackbar({
          open: true,
          type: SnackbarTypes.Error,
          content: "The post can't be deleted",
        }));
      }
    }, [deletePostData, loadUser, dispatch]);

  useEffect(() => {
    if (deletePostResult === true) {
      dispatch(setConfirmDialog({ result: false }));
      deletePost();
    }
  }, [deletePostResult, deletePost, dispatch]);

  const contentPostDeleteClick = useCallback(
    /** @param {PostModel} post */
    (post) => {
      dispatch(setConfirmDialog({
        open: true,
        header: getConfirmDialogHeader('post'),
        content: getConfirmDialogContent('post'),
        data: post,
        result: null,
      }))
    }, [dispatch]);

  const contentNftSuspend = useCallback(async () => {
    try {
      const {
        sale_allowed: saleAllowed,
        sale_trusted: saleTrusted,
      } = values;
      await NftApi.suspend({
        userId,
        saleAllowed: !saleAllowed,
        saleTrusted: !saleTrusted,
      })
      loadUser(false);
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Success,
        content: `The user's nft has been suspended`,
      }));
    } catch (error) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: `Error: The user's nft hasn't been suspended`,
      }));
    }
  }, [userId, values, dispatch, loadUser]);

  return (
    <Layout
      breadcrumbs={[
        {
          link: '/',
          label: 'Home',
        },
        {
          label: 'Users',
          link: '/users',
        }, 
        {
          label: 'User',
          link: `/users/${userId}`,
        }
      ]}
    >
      <Container>
        {loader && (
          <Loader>
            <CircularProgress variant='indeterminate' color="inherit" />
          </Loader>
        )}
        {!loader && user && (
          <FormikProvider value={form}>
            <Header 
              user={user}
            />
            <Content 
              user={user}
              tab={tab}
              originalPosts={originalPosts}
              metaOfOriginalPosts={metaOfOriginalPosts}
              reboundPosts={reboundPosts}
              metaOfReboundPosts={metaOfReboundPosts}
              nftPosts={nftPosts}
              onPostClick={contentPostClick}
              onPostDeleteClick={contentPostDeleteClick}
              onTabChange={contentTabChange}
              onAboutSave={contentAboutSave}
              attributes={attributes}
              onAttributesSave={attributesSave}
              onNftSuspend={contentNftSuspend}
              onPostsNext={postsNext}
              onReboundsNext={reboundsNext}
            />
          </FormikProvider>
        )}
      </Container>
    </Layout>
  )
};