import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import {
  ContentCopy as ContentCopyIcon,
} from '@mui/icons-material';
import { Box } from '../../../components/Box';
import { Text } from '../../../components/Text';
import { TextColors, TextLabels } from '../../../constants';
import { useUtility } from '../../../utilities/hooks';
import { getFormattedTextByIsoString, getFormattedTextByStatusNumber } from '../../../utilities';

const StyledCopyIcon = styled(ContentCopyIcon)`
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 138px;
  margin: 0 0 25px 0;
  cursor: pointer;
`;

const Image = styled.img`
  display: inline-flex;
  width: 245px;
  height: 138px;
  border-radius: 6px;
  border: 1px solid #fff;
`;

/** @typedef {import('react').ReactNode} ReactNode */
/** @typedef {import('../../../models/reported-post.model').ReportedPostModel} ReportedPostModel */

/**
 * @param {object} props
 * @param {ReportedPostModel} props.reportedPost
 * @returns {ReactNode}
 */
export const ReportedPostCard = ({
  reportedPost,
}) => {
  const post = reportedPost?.post;
  const user = post?.user;
  const userSetting = user?.userSettings;
  const attributes = useMemo(() => {
    if (!reportedPost?.user?.userSettings?.attributes?.length) {
      return <>None</>;
    }
    return Object.keys(reportedPost.user.userSettings.attributes).map(v => v.substring(2)).join(', ');
  }, [reportedPost]);

  const {
    copyClick,
    navigateClick,
  } = useUtility();

  return (
    <Container>
      <Box>
        <Image
          src={post?.thumb_url ?? post?.first_frame}
          onClick={navigateClick(`/posts/${post?.post_id}`)}
        />
      </Box>
      <Box 
        flexDirection="column" 
        padding="0 0 0 10px" 
        minWidth="200px" 
        onClick={navigateClick(`/posts/${post?.post_id}`)}
      >
        <Box>
          <Text color={TextColors.Name2}>
            {userSetting?.full_name ?? <>&lt;No Full Name&gt;</>}
          </Text>
        </Box>
        <Box>
          <Text color={TextColors.Name}>
            @{user?.user_name}
          </Text>
        </Box>
        <Box>
          <Text color={TextColors.Info}>
            {userSetting?.bio_short ?? <>&lt;No Short Bio&gt;</>}
          </Text>
        </Box>
      </Box>
      <Box flexDirection="column" minWidth="200px" padding="0 0 0 10px">
        <Box>
          <Text color={TextColors.Status}>{getFormattedTextByStatusNumber(reportedPost?.status) ?? TextLabels.Empty}</Text>
        </Box>
        <Box gap="5px">
          <Text color={TextColors.Label}>Post ID:</Text>
          <Text color={TextColors.ID}> {reportedPost.post_id}</Text>
        </Box>
      </Box>
      <Box flexDirection="column">
        <Box gap="5px">
          <Text color={TextColors.Label}>
            Reported Post ID:
          </Text>
          <Text color={TextColors.ID2}>
            {reportedPost?.reported_post_id}
          </Text>
          <Box>
            <StyledCopyIcon onClick={copyClick(reportedPost.post_id, 'The post ID has been copied')} />
          </Box>
        </Box>
        <Box gap="5px">
          <Text color={TextColors.Label}>
            Created:
          </Text>
          <Text color={TextColors.DateTime}>
            {getFormattedTextByIsoString(reportedPost.created) ?? TextLabels.Empty}
          </Text>
        </Box>
        <Box gap="5px">
          <Box>
            <Text color={TextColors.Label}>
              Attributes:
            </Text>
          </Box>
          <Box position="relative" top="3px">
            <Text 
              display="block" 
              color={TextColors.List} 
              width="350px" 
              wordWrap="break-all"
              size="14px"
            >
              {attributes}
            </Text>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};