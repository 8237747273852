import React, { useCallback, useMemo } from 'react';
import { Container } from "../../../../components/Container";
import { useUtility } from '../../../../utilities/hooks';
import { Box } from '../../../../components/Box';
import { Text } from '../../../../components/Text';
import { getChipColorOfPostStatus, getFormattedTextByIsoString, getPostStatusLabel, getSaleStatus, uuidToISOStringOfDate } from '../../../../utilities';
import { UserCard } from '../../../../components/UserCard';
import styled from '@emotion/styled';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import { format } from 'date-fns';
import { Chip } from '@mui/material';
import { TextColors, TextLabels } from '../../../../constants';

const StyledContentCopyIcon = styled(ContentCopyIcon)`
  cursor: pointer;
`;

const Image = styled.img`
  display: flex;
  width: 360px;
  height: 205px;
  object-fit: contain;
  border-radius: 6px;
  border: 1px solid #373737;
`;

/** @typedef {import('../../../../models/post.model').PostModel} PostModel */

/**
 * @param {object} props
 * @param {PostModel} props.post
 * @returns 
 */
export const Details = ({
  post,
}) => {
  const {
    copyClick,
    navigate,
  } = useUtility();

  const videoLength = useMemo(() => {
    if (!post.video_length_ms) {
      return TextLabels.Empty;
    }
    const date = new Date(post.video_length_ms);
    return format(date, 'HH:mm:ss');
  }, [post]);

  const userClick = useCallback(() => {
    navigate(`/users/${post.author_id}`, { label: 'User' })
  }, [post, navigate]);

  return (
    <Container>
      <Container>
        <Box gap="5px">
          <Text color={TextColors.Info}>{post.height}x{post.width}</Text>
          <Text>|</Text>
          <Text color={TextColors.Info}>{videoLength}</Text>
        </Box>
        <Box gap="5px">
          <Text color={TextColors.Label}>Created On:</Text>
          <Text>{getFormattedTextByIsoString(uuidToISOStringOfDate(post.post_id))}</Text>
        </Box>
        <Box gap="5px">
          <Text color={TextColors.Label}>Type:</Text>
          <Text>{post.post_type}</Text>
        </Box>
        <Box gap="5px">
          <Text color={TextColors.Label}>
            Status: 
          </Text>
          <Chip 
            label={getPostStatusLabel(post?.post_status ?? '', true)} 
            color={getChipColorOfPostStatus(post?.post_status ?? '')}
            size="small" 
          />
        </Box>
        <Box gap="5px">
          <Text color={TextColors.Label}>Device Meta:</Text>
          <Text>{post.device_meta}</Text>
        </Box>
        <Box gap="5px">
          <Text color={TextColors.Label}>Post Sell Status:</Text>
          <Text>{getSaleStatus(post.sale_status)}</Text>
        </Box>
        <Box gap="5px">
          <Text color={TextColors.Label}>Post ID:</Text>
          <Text>{post.post_id}</Text>
          <Box>
            <StyledContentCopyIcon onClick={copyClick(post.post_id, 'The post ID has been copied')} />
          </Box>
        </Box>
        {post.post_origin_id && (
          <Box gap="5px">
            <Text color={TextColors.Label}>Origin Post ID:</Text>
            <Text>{post.post_origin_id}</Text>
            <Box>
              <StyledContentCopyIcon onClick={copyClick(post.post_id, 'The post ID has been copied')} />
            </Box>
          </Box>
        )}
        {post.post_parent_id && (
          <Box gap="5px">
            <Text color={TextColors.Label}>Parent Post ID:</Text>
            <Text>{post.post_parent_id}</Text>
            <Box>
              <StyledContentCopyIcon onClick={copyClick(post.post_id, 'The post ID has been copied')} />
            </Box>
          </Box>
        )}
      </Container>
      <Box gap="5px">
        <Box flexDirection="column">
          <Box>
            <Text color={TextColors.Label}>First Frame</Text>
          </Box>
          <Box>
            <Image src={post.first_frame} alt="" />
          </Box>
        </Box>
        <Box flexDirection="column">
          <Box>
            <Text color={TextColors.Label}>Thumbnail Image</Text>
          </Box>
          <Box>
            <Image src={post.thumb_url} alt="" />
          </Box>
        </Box>
        <Box flexDirection="column">
          <Box>
            <Text color={TextColors.Label}>Rebound GIF</Text>
          </Box>
          <Box>
            <Image src={post.rebound_image} alt="" />
          </Box>
        </Box>
      </Box>
      <Container>
        <UserCard 
          user={post.user} 
          isDeleteButton={false}
          onClick={userClick}
          onCopy={copyClick(post.post_id, 'The user ID has been copied')}
        />
      </Container>
    </Container>
  )
}