import React, { useCallback, useEffect, useState } from 'react';
import { Layout } from '../../components/Layout';
import styled from '@emotion/styled';
import { Button, Table, TableBody, TableHead } from '@mui/material';
import { AccessApi } from '../../api/access.api';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MuiDeleteIcon from '@mui/icons-material/Delete';
import { TableRow } from '../../components/Table/TableRow';
import { TableCell } from '../../components/Table/TableCell';
import { FormTypes, SnackbarTypes } from '../../constants';
import { getConfirmDialog, setConfirmDialog, setSnackbar } from '../../store/ui';
import { FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getConfirmDialogContent, getConfirmDialogHeader } from '../../utilities';
import { useSearch } from '../../components/Search/hook';
import { Search } from '../../components/Search';
import { Box } from '../../components/Box';
import { Container } from '../../components/Container';
import { Form } from './Form';
import * as yup from 'yup';
import { Text } from '../../components/Text';
import { Row } from '../../components/Row';
import { DialogOfRoles } from '../../components/DialogOfRoles';
import { Accesses } from '../../constants/access';

const DeleteIcon = styled(MuiDeleteIcon)`
  color: #f00;
  fill: #f00;
`;

const PrivilegeKeys = {
  Email: 'email_address',
  Attributes: 'attributes',
}

const searchItems = [
  {
    label: 'Email Address',
    value: PrivilegeKeys.Email,
  },
  {
    label: 'Attributes',
    value: PrivilegeKeys.Attributes,
  }
]

export const PrivilegesExternal = () => {
  const dispatch = useDispatch();
  const [privilege, setPrivilege] = useState(null);
  const [rows, setRows] = useState([]);
  const [privileges, setPrivileges] = useState([]);
  const [formType, setFormType] = useState(FormTypes.Create);
  const [dialogOpen, setDialogOpen] = useState(false);
  const confirmDialogProps = useSelector(getConfirmDialog);
  const { 
    result: confirmDialogResult,
    data: confirmDialogData,
  } = confirmDialogProps;
  const [dialogOfRoles, setDialogOfRoles] = useState({
    open: false,
    value: [],
    title: 'Privileges',
    readonly: true,
  });

  const {
    searchItem,
    searchText,
    searchInputChange,
    searchSelectChange,
  } = useSearch({
    key: 'search:privileges',
    item: searchItems[0],
    text: '',
  });

  useEffect(() => {
    const {
      value,
    } = searchItem;
    const externalFilterCallback = v => !v.email_address.includes('@recrd.com');
    if (searchText === '') {
      const newPrivileges = privileges.filter(externalFilterCallback);
      setRows(newPrivileges);
    }
    if (value === PrivilegeKeys.Email) {
      const filterCallback = v => v.email_address.toLowerCase().includes(searchText.toLowerCase());
      const filteredRows = privileges
        .filter(externalFilterCallback)
        .filter(filterCallback);
      setRows(filteredRows);
    }
    if (value === PrivilegeKeys.Attributes) {
      const filterCallback = privilege => {
        const attributes = privilege?.attributes ?? [];
        const foundAttribute = attributes.find(
          attribute => attribute.toLowerCase().includes(searchText.toLowerCase()),
        )
        return !!foundAttribute;
      };
      const filteredRows = privileges
        .filter(externalFilterCallback)
        .filter(filterCallback);
      setRows(filteredRows);
    }
  }, [privileges, searchText, searchItem]);

  const getEmailValidation = useCallback(() => {
    let schema = yup.string()
      .email()
      .test({
        name: 'email',
        test: (value) => !value?.includes('@recrd.com'),
        message: 'The recrd email is not allowed',
      })
      .required();
    if (process.env.REACT_APP_NODE_ENV !== 'production') {
      schema = schema.test({
        name: 'email',
        test: (value) => !value?.includes('+'),
        message: "The email shouldn't include the plus symbol"
      })
    };
    return schema;
  }, []);

  const form = useFormik({
    initialValues: {
      email: '',
      attributes: [],
    },
    validationSchema: yup.object().shape({
      email: getEmailValidation(),
      attributes: yup.array()
        .of(yup.string())
        .min(1, 'The attributes should have at least one attribute')
        .required()
    })
  });

  const {
    values,
    isValid,
    setValues,
  } = form;

  const loadPrivileges = useCallback(async () => {
    const newPrivileges = await AccessApi.getPrivileges();
    setPrivileges(newPrivileges);
  }, []);

  useEffect(() => {
    loadPrivileges();
  }, [loadPrivileges]);

  const deleteFeature = useCallback(async () => {
    try {
      dispatch(setConfirmDialog({
        open: false,
      }));
      await AccessApi.deletePrivilege(confirmDialogData.email_address);
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Success,
        content: 'The privilege has been deleted',
      }));
      loadPrivileges();
    } catch (error) {
      dispatch(setSnackbar({
        open: false,
        type: SnackbarTypes.Error,
        content: 'Error: The privilege hasn\'t been deleted',
      }));
    }
  }, [confirmDialogData, dispatch, loadPrivileges]);

  useEffect(() => {
    if (confirmDialogResult === true) {
      deleteFeature()
    }
  }, [confirmDialogResult, deleteFeature]);

  const createPrivilegeClick = useCallback(() => {
    setFormType(FormTypes.Create);
    setPrivilege(null);
    setValues({
      ...values,
      email: '',
      attributes: [],
    })
    setDialogOpen(true);
  }, [values, setValues]);

  const editPrivilegeClick = useCallback((selectedPrivilege) => () => {
    setFormType(FormTypes.Update);
    setPrivilege(selectedPrivilege);
    setValues({
      ...values,
      email: selectedPrivilege.email_address.replace('@recrd.com', ''),
      attributes: [...(selectedPrivilege?.attributes ?? [])],
    })
    setDialogOpen(true);
  }, [values, setValues]);

  const deletePrivilegeClick = useCallback((selectedPrivilege) => () => {
    dispatch(setConfirmDialog({
      open: true,
      header: getConfirmDialogHeader(),
      content: getConfirmDialogContent('privilege'),
      result: null,
      data: selectedPrivilege,
    }));
  }, [dispatch]);

  const dialogClose = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const createPrivilege = useCallback(async () => {
    try {
      let email;
      email = values.email;
      if (email.includes('@recrd.com')) {
        throw Error();
      }
      await AccessApi.createPrivilege({
        email,
        attributes: values.attributes,
      });
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Success,
        content: 'the new privilege has been created'
      }));
      loadPrivileges();
    } catch (error) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: 'Error: the new privilege hasn\'t been created',
      }));
    } finally {
      setDialogOpen(false);
    }
  }, [values, loadPrivileges, dispatch]);

  const updatePrivilege = useCallback(async () => {
    try {
      await AccessApi.updatePrivilege(privilege.email_address, {
        email: values.email,
        attributes: values.attributes,
      });
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Success,
        content: 'the new privilege has been updated'
      }));
      loadPrivileges();
    } catch (error) {
      dispatch(setSnackbar({
        open: true,
        type: SnackbarTypes.Error,
        content: 'Error: the new privilege hasn\'t been updated',
      }));
    } finally {
      setDialogOpen(false);
    }
  }, [privilege, values, loadPrivileges, dispatch]);

  const dialogSubmit = useCallback(() => {
    if (!isValid) {
      return;
    }
    if (formType === FormTypes.Create) {
      createPrivilege()
    }
    if (formType === FormTypes.Update) {
      updatePrivilege();
    }
  }, [formType, isValid, createPrivilege, updatePrivilege]);

  const dialogOfRolesOpen = useCallback((privilege) => () => {
    setDialogOfRoles(state => ({
      ...state,
      open: true,
      value: privilege.attributes,
    }))
  }, []);

  const dialogOfRolesClose = useCallback(() => {
    setDialogOfRoles(state => ({
      ...state,
      open: false,
    }))
  }, []);

  return (
    <Layout 
      breadcrumbs={[
        {
          link: '/',
          label: 'Home',
        },
        {
          label: 'Privileges (External)',
          link: '/privileges/external',
        }, 
      ]}
    >
      <Container>
        <Row gap="10px">
          <Box flex="1">
            <Search
              items={searchItems}
              item={searchItem}
              text={searchText}
              onInputChange={searchInputChange}
              onSelectChange={searchSelectChange}
            />
          </Box>
          <Box padding="0px 0 0 180px" height="56px">
            <Button
              variant="contained"
              color="warning"
              onClick={createPrivilegeClick}
            >
              Create
            </Button>
          </Box>
        </Row>
        <Container>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Text>Email Address</Text>
                </TableCell>
                <TableCell>
                  <Text>Attributes</Text>
                </TableCell>
                <TableCell>
                  <Text>Actions</Text>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(privilege => (
                <TableRow key={privilege.email_address}>
                  <TableCell>
                    <Text>{privilege.email_address}</Text>
                  </TableCell>
                  <TableCell>
                    <Box onClick={dialogOfRolesOpen(privilege)}>
                      <Text>{privilege.attributes?.join(', ')}</Text>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="inline-flex">
                      <Box>
                        <ModeEditIcon onClick={editPrivilegeClick(privilege)} />
                      </Box>
                      <Box>
                        <DeleteIcon onClick={deletePrivilegeClick(privilege)} />
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <FormikProvider value={form}>
            <Form
              access={Accesses.External}
              formType={formType}
              show={dialogOpen}
              onCancel={dialogClose}
              onSubmit={dialogSubmit}
            />
          </FormikProvider>
          <DialogOfRoles
            access={Accesses.External}
            title={dialogOfRoles.title}
            open={dialogOfRoles.open}
            value={dialogOfRoles.value}
            readonly={dialogOfRoles.readonly}
            onClose={dialogOfRolesClose}
          />
        </Container>
      </Container>
    </Layout>
  )
};